import React from "react"
import Layout from "../layout/GeneralLayout"
import {
  Box,
  Heading,
  Paragraph,
  GlassBox,
  Anchor,
  Button,
} from "../components/commonElements"
import styled from "styled-components"
import Footer from "../components/footer"
import SEO from "../components/seo"
import AccountForm from "../components/accountForm"

const OuterContainer = styled(Box)`
  width: 100%;
  align-items: center;
  min-height: 78vh;
  @media ${props => props.theme.device.tablet} {
    min-height: 83vh;
  }
`

const Container = styled(GlassBox)`
  font-size: 1.8rem;
  max-width: 70rem;
  align-items: center;
  width: calc(100% - 2rem);
`

const InputBox = styled(Box)`
  flex: 2;
  max-width: 80rem;
  width: 100%;
  @media (max-width: 1200px) {
    flex: 100%;
  }
`

const login = () => {
  return (
    <Layout>
      <Box flex="1" mt="1rem" alignItems="center" gap="1rem">
        <OuterContainer>
          <Container
            backgroundColor="white"
            padding="2rem 2rem 1.5rem"
            minHeight="48.8rem"
          >
            <Heading level={1} textAlign="center" mb=".8rem">
              Welcome back.
            </Heading>
            <Paragraph level={4} textAlign="center" mb="0">
              Log in to save & record all your fabulous customizations.
            </Paragraph>
            <Paragraph level={4} textAlign="center" mb="2rem">
              No Account?&nbsp;
              <Anchor
                href="/signup/"
                style={{
                  textDecoration: `underline`,
                }}
                alt="signup"
              >
                SIGN UP
              </Anchor>
            </Paragraph>
            <InputBox>
              <AccountForm />
            </InputBox>
          </Container>
        </OuterContainer>
        <Footer />
      </Box>
    </Layout>
  )
}

export default login

export const Head = () => (
  <SEO
    titleP="Login | FlipSimu"
    descriptionP="This is the login page of FlipSimu"
    pathname="/login/"
  />
)
